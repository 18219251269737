h1,
h2,
h3,
h4,
h5 {
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
}

body {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
}
